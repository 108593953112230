import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './tailwind.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Analytics } from './pages/Analytics/Analytics';
import { Layout } from './components/Layout';
import { HeadingTitle } from './components/HeadingTitle';
import { CompxDescription } from './components/CompxDescription';
import { LiquiditySources } from './components/LiquiditySources';
import { CompxRoutesDetails } from './components/CompxRoutesDetails';
import { Resources } from './components/Resources';
import { Footer } from './components/Footer';
import FeatureSection from './components/FeatureSection';
import LogoCloud from './components/LogoCloud';
import CTASection from './components/CTASection';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const appRouter = createBrowserRouter([
    {
        path: '/',
        element: (
            <Layout>
                <HeadingTitle />
                <FeatureSection />
                <CTASection />
            </Layout>
        ),
        errorElement: <h1>Error</h1>,
    },
    {
        path: '/analytics',
        element: (
            <Layout>
                <Analytics />
                <Resources />
                <Footer />
            </Layout>
        ),
    },
]);
root.render(<RouterProvider router={appRouter} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
