import { FC } from 'react';
import { NavigationDrawerProvider } from '../../context/navigationDrawer';
import { Header } from '../Header';
import { NavDrawer } from '../NavDrawer';

interface LayoutProps {
    children: JSX.Element | JSX.Element[];
}

export const Layout: FC<LayoutProps> = ({ children }) => {
    return (
        <div className={`w-full h-full overflow-auto`}>
            <NavigationDrawerProvider>
                <Header />
                <NavDrawer />
            </NavigationDrawerProvider>
            <div className="content w-screen h-screen">{children}</div>
        </div>
    );
};
