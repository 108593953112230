export default function CTASection() {
    return (
      <div className="bg-black">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="relative isolate overflow-hidden bg-black px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Boost your yield today.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Join the next DeFi revolution and gain access to the highest yields in the market.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <div
                onClick={() => {
                  window.open('https://app.compx.io', '_blank');
              }}
                className="rounded-full bg-white px-3.5 py-2.5 text-sm  text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
              >
                Launch App
              </div>
              
            </div>
           
          </div>
        </div>
      </div>
    )
  }
  