export const navigationItems = [
   /*  {
        id: '01',
        title: 'Resources',
        link: '/',
        isExternalLink: false,
        jumpToContent: 'resources',
    }, */
    // {
    //     id: '02',
    //     title: 'Analytics',
    //     link: '/analytics',
    //     isExternalLink: false,
    //     jumpToContent: false,
    // },
    {
        id: '01',
        title: 'Docs',
        link: 'https://compx.gitbook.io/compx/',
        isExternalLink: true,
        jumpToContent: false,
    },
];
