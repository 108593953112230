import React from 'react';
import './Button.css';

interface ButtonProps {
    name?: string;
    redirectTo?: string;
    classes?: string;
    onClickHandler?: any;
    disabled?: boolean;
    style?: any;
    hoverMessage?: string;
}

export const Button: React.FC<ButtonProps> = ({
    name,
    redirectTo,
    classes,
    onClickHandler,
    disabled,
    style,
    hoverMessage,
}) => {
    return (
        <div className={`group inline-block relative`}>
            <button
                onClick={
                    !disabled
                        ? redirectTo
                            ? window.open(redirectTo, '_blank')
                            : onClickHandler
                        : null
                }
                className={`${name}-btn hover:text-black border border-transparent flex justify-center items-center bg-[#ffffff] cursor-pointer text-black   hover:bg-[#E4E4E4] h-[35px]  rounded-full ${
                    classes ? classes : ''
                } ${disabled ? 'disabled-btn' : ''}`}
                disabled={disabled}
                style={style}
            >
                {name}
            </button>
            {hoverMessage && (
                <div className="invisible cursor-default group-hover:visible absolute w-64 py-2 px-2 -mt-24 left-1/2 transform -translate-x-1/2 bg-white text-black  text-center rounded-lg shadow-lg font-extralight text-sm xs:text-xs">
                    {hoverMessage}
                </div>
            )}
        </div>
    );
};
