import React, { useState, useEffect } from 'react';
import './NavItem.css';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { navigationItems } from '../../constants/navigationItems';
import { Link, animateScroll as scroll } from 'react-scroll';

interface NavItemsProps {
    toggleState?: Boolean;
    navItemHandler?: () => void;
}

interface INavItems {
    id: string;
    title: string;
    link: string;
    isExternalLink: Boolean;
    jumpToContent: any;
}

export const NavItems: React.FC<NavItemsProps> = ({
    toggleState,
    navItemHandler,
}) => {
    const [navItems, setNavItems] = useState<INavItems[]>(navigationItems);
    const [isMobileScreen, setIsMobileScreen] = useState<Boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            setIsMobileScreen(true);
        } else {
            setIsMobileScreen(false);
        }
    }, []);

    useEffect(() => {
        if (isMobileScreen) {
            setNavItems((navItems) => {
                return navItems.filter(({ title }) => {
                    return title !== '...';
                });
            });
        } else {
            setNavItems(navigationItems);
        }
    }, [isMobileScreen]);

    useEffect(() => {
        if (location.pathname === '/') {
            scroll.scrollToTop();
        }
    }, [location]);

    return (
        <div>
            <ul
                className={`${
                    !toggleState && 'flex xs:flex-col sm:flex-col p-4'
                } menu-item md:inline-flex `}
            >
                {' '}
                {navItems.map(
                    (
                        { id, title, link, isExternalLink, jumpToContent },
                        index
                    ) => {
                        return (
                            <li
                                key={index}
                                className={`flex sm:px-4 md:px-4 px-5 text-base nav-item font-light mx-2 py-2  text-white cursor-pointer hover:opacity-80`}
                            >
                                {isExternalLink ? (
                                    <a
                                        href={link}
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        <div className="flex space-x-0.5">
                                            <div className="text-[10px] leading-[8px]">
                                                {id}
                                            </div>
                                            <div>{title}</div>
                                        </div>
                                    </a>
                                ) : jumpToContent ? (
                                    <Link
                                        activeClass="active"
                                        to={jumpToContent}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                    >
                                        <div className="flex space-x-0.5">
                                            <div className="text-[10px] leading-[8px]">
                                                {id}
                                            </div>
                                            <div>{title}</div>
                                        </div>
                                    </Link>
                                ) : (
                                    <NavLink
                                        to={link}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'nav-item-selected'
                                                : 'text-white'
                                        }
                                    >
                                        <div className="flex space-x-0.5">
                                            <div className="text-[10px] leading-[8px]">
                                                {id}
                                            </div>
                                            <div>{title}</div>
                                        </div>
                                    </NavLink>
                                )}
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
};
