import React from 'react';
import './Resources.css';

export const Resources: React.FC = () => {
    const resources = [
        {
            Documentation: 'Documents',
            Community: 'Discord (Coming Soon)',
            DocumentationURL: 'https://compx.gitbook.io/compx/',
            CommunityURL: null,
        },
        {
            Documentation: 'Analytics (Coming Soon)',
            Community: 'X',
            DocumentationURL: null,
            CommunityURL: 'https://twitter.com/Compxlabs',
        },
        {
            Documentation: 'Deflex',
            Community: 'Telegram',
            DocumentationURL: 'https://www.deflex.fi/',
            CommunityURL: 'https://t.me/Alammex_Compx',
        },
    ];
    return (
        <div
            className="flex flex-col mt-24 text-white xs:px-4 sm:px-4 md:px-4 lg:px-4 xl:px-28 2xl:px-28"
            id="resources"
        >
            <div className="text-white md:text-xl 2xl:text-6xl xl:text-6xl lg:text-6xl xs:text-xs sm:text-sm my-16 xs:my-8 sm:my-8">
                Resources
            </div>
            <hr className="mt-4 bg-[#FFFFFF]  border-1 border-solid" />
            <div className="flex w-full 2xl:text-2xl xl:text-2xl lg:text-2xl xs:text-xs sm:text-sm md:text-s mt-2 mb-8">
                <div className="w-1/2">Documentation</div>
                <div className="w-1/2">Community</div>
            </div>
            {resources.map(
                (
                    {
                        Documentation,
                        Community,
                        DocumentationURL,
                        CommunityURL,
                    },
                    index
                ) => {
                    return (
                        <React.Fragment key={index}>
                            <hr className="mt-1 sources-border" />
                            <div
                                className={`flex my-2 text-[#AAAAAA] text-lg xs:text-xs sm:text-sm font-light`}
                            >
                                <div
                                    className={`w-1/2 hover:text-white ${
                                        Documentation
                                            ? 'resource-item cursor-pointer'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (DocumentationURL) {
                                            window.open(
                                                DocumentationURL,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    {Documentation}
                                </div>
                                <div
                                    className={`w-1/2 hover:text-white ${
                                        Community
                                            ? 'resource-item cursor-pointer'
                                            : ''
                                    } `}
                                    onClick={() => {
                                        if (CommunityURL) {
                                            window.open(CommunityURL, '_blank');
                                        }
                                    }}
                                >
                                    {Community}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            )}
        </div>
    );
};
