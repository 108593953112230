import React from 'react';

interface AnalyticsDetailsProps {
    title?: string;
    value?: string;
}

export const AnalyticsDetailsContainer: React.FC<AnalyticsDetailsProps> = ({
    title,
    value,
}) => {
    return (
        <div className="analytics-container flex flex-col space-y-2 px-2 text-white bg-[#000000] w-[400px] rounded-lg border border-[#D9D9D9]">
            <div className="flex justify-between items-center">
                {' '}
                <h6 className="text-white xs:text-xs text-sm">{title}</h6>
            </div>
            <br />
            <hr className="border-white  border-1 border-solid" />
            <div className="flex justify-end">{value}</div>
        </div>
    );
};
