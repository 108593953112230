import React, { useState } from 'react';
import { AnalyticsDetailsContainer } from '../../components/AnalyticsDetailsContainer';
import { AnalyticsOverviewTable } from '../../components/AnalyticsOverviewTable';
import './Analytics.css';

export const Analytics: React.FC = () => {
    const [options, setOptions] = useState<string[]>(['Pools', 'Transactions']);
    const [selectedPageOption, setSelectedPageOption] = useState('Pools');
    return (
        <div className="flex flex-col w-full h-full space-y-4 mt-28 text-white">
            <div className="flex flex-col">
                <div className="flex justify-center xs:flex-col sm:flex-col xs:space-x-0 sm:space-x-0 xs:space-y-4 sm:space-y-4 space-x-10 items-center mt-4 ">
                    <AnalyticsDetailsContainer
                        title="Total Liquidity"
                        value="$000000"
                    />
                    <AnalyticsDetailsContainer
                        title="Volume 24h/7Day"
                        value="$000000"
                    />
                    <AnalyticsDetailsContainer
                        title="Average APR Increase"
                        value="$000000"
                    />
                </div>
                <div className="flex flex-col w-full h-full space-y-4 2xl:px-16 xl:px-16 lg:px-4 md:px-4 sm:px-4 xs:px-4">
                    <div className="flex justify-between mx-4 mt-4">
                        <h1 className="analytics-title font-light text-white">
                            Transactions
                        </h1>
                        {/* <div>
                            <DropDownFilterButton />
                        </div> */}
                    </div>
                    <div className="w-full h-full">
                        <AnalyticsOverviewTable />
                    </div>
                </div>
            </div>
        </div>
    );
};
