import React, { useState, useEffect } from 'react';
import './HeadingTitle.css';
import { Video } from '../../utils/misc';

export const HeadingTitle: React.FC = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div
            className={`heading-title-wrapper video-container flex flex-col justify-center  items-center text-white p-4 h-full w-full transition-opacity duration-500  ${
                scrollPosition > 50 ? 'bg-black opacity-5' : 'bg-transparent'
            } absolute inset-0`}
        >
            <video
                autoPlay
                loop
                muted
                className="video w-full h-full  absolute inset-0"
            >
                <source src={Video('background-video-5.mp4')} type="video/mp4" />
            </video>
            <div className=" heading-title ">
                <div className="text-5xl cursor-default text-center mb-6">
                    {' '}
                    The next revolution in DeFi.
                </div>
                <div className="flex justify-center">
                    <div
                        className={`backdrop-blur-md  text-white text-center text-xl cursor-pointer flex justify-center rounded-full w-[280px]`}
                        onClick={() => {
                            window.open('https://app.compx.io', '_blank');
                        }}
                    >
                        Launch CompX
                    </div>
                </div>
            </div>
        </div>
    );
};
