import React, { useContext } from 'react';
import { NavItems } from '../NavItems';
import { socialIcons } from '../../constants/socialIcons';
import './NavDrawer.css';
import { NavigationDrawerContext } from '../../context/navigationDrawer';

export const NavDrawer: React.FC = () => {
    const { toggle } = useContext(NavigationDrawerContext);

    return (
        <div
            id="sidenav"
            className={`sidenav h-full w-full bg-[#1E1E1E] ${
                toggle ? 'block' : 'hidden'
            }`}
        >
            <div className="nav-items">
                <NavItems />
            </div>
            <div className="socials">
                {socialIcons.map((item, i) => {
                    return (
                        <span
                            key={i}
                            className="cursor-pointer"
                            onClick={() => {
                                window.open(item.link, '_blank');
                            }}
                        >
                            {item.icon}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};
