import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { IMG } from '../utils/misc'

const features = [
  {
    name: 'Auto-compounding Farms',
    description: 'By compounding your rewards back into your capital, CompX can always give you better rewards than the equivelant farm on other platforms.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Smart Swap routing and limit orders',
    description: 'Find the best trades at the best prices, everytime.',
    icon: LockClosedIcon,
  },
  {
    name: 'Token Streaming',
    description: 'The smart solution to implement vesting schedules, token locks and token distribution.',
    icon: ServerIcon,
  },
  {
    name: 'Portfolio Tracking',
    description: 'Track your positions and tokens across a range of DeFi protocols.',
    icon: ServerIcon,
  },
]

export default function FeatureSection() {
  return (
    <div className="overflow-hidden bg-black pt-24 sm:pt-32 items-center">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 items-center">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4 ">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-orange-500">Higher yields</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Our innovative tools</p>
              <p className="mt-6 text-lg leading-8 text-gray-400">
                Our mission is to consistantly innovate and create products that will help you achieve the best yields.
              </p>
              <dl className="mt-10 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-orange-500" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline text-gray-400">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          
          <img
            src={IMG(`screen.png`, 'pages')}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}
