import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '../Button';

export const AnalyticsOverviewTable: React.FC = () => {
    function createData(
        name: string | React.ReactNode,
        TVL: number | string,
        Vol: number | string,
        APY: number | string,
        APR: number | string,
        APRIncrease: number | string
    ) {
        return { name, TVL, Vol, APY, APR, APRIncrease };
    }
    const ASAPairDetails = (
        asset1Name: string,
        asset1Id: string,
        asset2Name: string,
        asset2Id: string
    ) => {
        return (
            <div className="flex  items-center space-x-1">
                <img
                    src={`https://assets.pact.fi/currencies/MainNet/${asset1Id}.image`}
                    className="h-6 w-6"
                    alt="lp-pair-asset1"
                />
                <img
                    src={`https://assets.pact.fi/currencies/MainNet/${asset2Id}.image`}
                    className="h-6 w-6"
                    alt="lp-pair-asset2"
                />
                <div className="farmDetails-asset">
                    {asset1Name ? asset1Name : 'Unavailable'}
                </div>
                <div className="farmDetails-asset">/</div>
                <div className="farmDetails-asset">
                    {asset2Name ? asset2Name : 'Unavailable'}
                </div>
            </div>
        );
    };
    const rows = [
        createData(
            ASAPairDetails('ALGO', '0', 'USDC', '31566704'),
            'xxx',
            'xxx',
            'xxx',
            'xxx',
            'xxx'
        ),
        createData(
            ASAPairDetails('ALGO', '0', 'USDC', '31566704'),
            'xxx',
            'xxx',
            'xxx',
            'xxx',
            'xxx'
        ),
        createData(
            ASAPairDetails('ALGO', '0', 'USDC', '31566704'),
            'xxx',
            'xxx',
            'xxx',
            'xxx',
            'xxx'
        ),
        createData(
            ASAPairDetails('ALGO', '0', 'USDC', '31566704'),
            'xxx',
            'xxx',
            'xxx',
            'xxx',
            'xxx'
        ),
        createData(
            ASAPairDetails('ALGO', '0', 'USDC', '31566704'),
            'xxx',
            'xxx',
            'xxx',
            'xxx',
            'xxx'
        ),
    ];
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ color: 'white' }}>Name</TableCell>
                        <TableCell style={{ color: 'white' }} align="right">
                            TVL
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="right">
                            24hr Vol
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="right">
                            APY
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="right">
                            APR
                        </TableCell>
                        <TableCell style={{ color: 'white' }} align="right">
                            APR Increase
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                style={{ color: 'white' }}
                            >
                                {row.name}
                            </TableCell>
                            <TableCell align="right" style={{ color: 'white' }}>
                                {row.TVL}
                            </TableCell>
                            <TableCell align="right" style={{ color: 'white' }}>
                                {row.Vol}
                            </TableCell>
                            <TableCell align="right" style={{ color: 'white' }}>
                                {row.APY}
                            </TableCell>
                            <TableCell align="right" style={{ color: 'white' }}>
                                {row.APR}
                            </TableCell>
                            <TableCell align="right" style={{ color: 'white' }}>
                                {' '}
                                <Button
                                    name={`+ ${row.APRIncrease}%`}
                                    classes="bg-[#F54702] w-20 md:w-20 sm:w-20 md:w-20 text-black hover:bg-[#F54702]  text-sm xs:text-xs"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
