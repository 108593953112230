import React, { useContext } from 'react';
import './Header.css';
import { NavItems } from '../NavItems';
import { NavigationDrawerContext } from '../../context/navigationDrawer';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';

export const Header: React.FC = () => {
    const { toggle, changeDrawerState } = useContext(NavigationDrawerContext);
    const navigate = useNavigate();
    const navItemHander = () => (toggle ? changeDrawerState() : null);

    const toggleMenu = (x: any) => {
        changeDrawerState();
        x.currentTarget.classList.toggle('change');
    };

    return (
        <header
            className={`flex flex-col xs:px-2 md:px-2 lg:px-2 xl:px-2 2xl:px-2 h-full w-full 2xl:sticky 2xl:top-0 xl:sticky xl:top-0`}
        >
            <nav
                className={`nav  flex justify-between  xs:max-h-28 xs:min-h-28 sm:max-h-28 sm:min-h-28 md:max-h-28 md:min-h-28 lg:max-h-28 lg:min-h-28 xl:max-h-28 xl:min-h-28 2xl:max-h-28 2xl:min-h-28  xs:mt-4 sm:px-8 sm:mt-4
        ${!toggle && 'nav-bg'}
        `}
            >
                <div className="text-white xs:hidden sm:hidden md:block w-1/3">
                    <NavItems
                        toggleState={toggle}
                        navItemHandler={navItemHander}
                    />
                </div>

                <div
                    className="w-1/3 flex justify-center cursor-pointer md:hidden"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <svg
                        width="130"
                        height="17"
                        viewBox="0 0 150 17"
                        className="fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_32_679)">
                            <path d="M0 6.36668C0 2.96668 3.12854 0.200012 6.97121 0.200012H9.07957H11.2219H12.5142H15.0306H17.547C21.1517 0.200012 24.1442 2.66668 24.4842 5.76668H21.8318C21.4917 3.93335 19.6894 2.50001 17.513 2.50001H14.9966H12.4802H11.2219H9.11358H7.00521C4.5908 2.50001 2.65246 4.23335 2.65246 6.33335V10.8333C2.65246 12.9333 4.6248 14.6667 7.00521 14.6667H9.11358H11.2219H12.5142H15.0306H17.547C19.7234 14.6667 21.5257 13.2667 21.8658 11.4H24.5182C24.1782 14.5 21.1857 16.9667 17.581 16.9667H15.0646H12.5482H11.2219H9.11358H7.00521C3.12854 17 0 14.2333 0 10.8667V6.36668Z" />
                            <path d="M38.1548 0.200012H42.1335H43.5957H44.922H46.9623H50.3969C54.2396 0.200012 57.3681 2.96668 57.3681 6.36668V10.8667C57.3681 14.2333 54.2396 17.0333 50.3969 17.0333H47.0984H44.922H43.5957H40.8753H38.1548C34.3121 17.0333 31.1836 14.2667 31.1836 10.8667V6.36668C31.1836 2.96668 34.3121 0.200012 38.1548 0.200012ZM40.8753 14.7H43.5957H44.922H47.0984H50.3969C52.8113 14.7 54.7497 12.9667 54.7497 10.8667V6.36668C54.7497 4.26668 52.7773 2.53335 50.3969 2.53335H46.9623H44.922H43.5957H42.1335H38.1548C35.7404 2.53335 33.802 4.26668 33.802 6.36668V10.8667C33.802 12.9667 35.7744 14.7 38.1548 14.7H40.8753Z" />
                            <path d="M64.8154 0.20002H68.148C68.4881 0.20002 68.7941 0.333354 68.9982 0.60002L78.7578 12.5334L87.9734 1.23335C88.5175 0.566687 89.3337 0.166687 90.2178 0.166687H92.7003V17H90.0818V2.86669L81.1043 13.6C80.6282 14.3 79.846 14.7 78.9959 14.7H78.5538C77.7037 14.7 76.8875 14.3 76.4114 13.6L67.4339 2.86669V17H64.8154V0.20002Z" />
                            <path d="M101.779 8.13333H116.776C118.578 8.13333 120.075 6.83333 120.075 5.23333C120.075 5.23333 120.075 5.23333 120.075 5.2C120.075 3.6 118.578 2.3 116.776 2.3H99.161V0H116.776C120.041 0 122.693 2.36667 122.693 5.23333C122.693 7.63333 120.823 9.73333 118.17 10.3L118.034 10.3333C117.626 10.4 117.184 10.4667 116.742 10.4667H113.613H101.745V16.8333H101.711C100.283 16.8333 99.127 15.7 99.127 14.3V10.4333V8.13333H101.779Z" />
                            <path d="M136.874 8.4L127.42 0H131.093L138.676 6.76667L145.579 0.666667C146.055 0.233333 146.667 0 147.313 0H150L140.546 8.4L150 16.8H146.327L138.744 10.0333L131.161 16.8H127.488L136.874 8.4Z" />
                        </g>
                        <defs>
                            <clipPath id="clip0_32_679">
                                <rect width="150" height="17" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="flex w-1/3 items-center text-white md:flex-row justify-center xs:hidden sm:hidden">
                    <Button
                        name="Launch App"
                        classes="w-52"
                        onClickHandler={() => {
                            window.open('https://app.compx.io', '_blank');
                        }}
                    />
                </div>

                <div className="md:hidden flex lg:hidden xl:hidden 2xl:hidden absolute right-4 menu-icon">
                    <div onClick={toggleMenu}>
                        <div className="triple-bars">
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};
